import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { AuthProvider } from "./utils/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// const registerServiceWorker = () => serviceWorkerRegistration.register();
// const unregisterServiceWorker = () => serviceWorkerRegistration.unregister();

// if (navigator.onLine) {
//   registerServiceWorker();
// } else {
//   unregisterServiceWorker();
// }

// window.addEventListener("online", registerServiceWorker);
// window.addEventListener("offline", unregisterServiceWorker);

// Initial check
// handleNetworkChange();
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
