// AbsenceModal.js
import React from "react";

const AbsenceModal = ({ isOpen, onClose, absences }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-lg font-semibold mb-4">Absence Details</h2>
        {absences.length > 0 ? (
          <ul>
            {absences.map((absence, index) => (
              <li key={index} className="mb-2">
                <strong>Type:</strong> {absence.TP_AUSENCIA} <br />
                <strong>Start:</strong> {absence.DT_INI} <br />
                <strong>End:</strong> {absence.DT_FIM} <br />
                <strong>Status:</strong> {absence.ESTADO} <br />
                <strong>Total MINUTOS:</strong> {absence.NR_MINUTOS} min.
              </li>
            ))}
          </ul>
        ) : (
          <p>No absence records found for this date.</p>
        )}
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AbsenceModal;
