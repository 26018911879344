/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast CSS
import line from "../assets/images/line.png";
import passwordIcon from "../assets/images/lock.png";
import loginIamge from "../assets/images/loginbackground.png";
import person from "../assets/images/user.png";
import LanguageSelector from "../component/LanguageSelector";
import { useLanguage } from "../context/LanguageProvider";
import { ToggleContext } from "../context/ToggleProvider";

const Login = () => {
  // const [language, setLanguage] = useState("pt");
  // const handleLanguageChange = (value) => {
  //   setLanguage(value);
  // };

  // const [language, setLanguage] = useState("pt");
  const { t } = useTranslation();
  const [utilizador, setUtilizador] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage() || {};
  // Use context language and setLanguage
  // console.log(language);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleLanguageChange = (value) => {
    setLanguage(value); // Update the language globally
  };
  const { toggle, setToggle } = useContext(ToggleContext); // Access toggle state and setter function here

  const handleToggle = () => {
    setToggle((prev) => !prev); // Toggles the state between true and false
  };

  //  useEffect(() => {
  //    const initFaceIO = async () => {
  //      try {
  //        const faceio = new faceIO("fioa8654");
  //       //  await faceio.init();
  //        console.log("FaceIO initialized successfully");
  //      } catch (error) {
  //        console.error("Failed to initialize FaceIO:", error);
  //      }
  //    };
  //    initFaceIO();
  //  }, []);

  // const handleFaceLogin = async () => {
  //  try {
  //    const faceio = new faceIO("fioa8654");
  //    const enrollOptions = {
  //      locale: "auto",
  //      payload: { email: "user@example.com" },
  //    };
  //    const userInfo = await faceio.enroll(enrollOptions);
  //    console.log("User registered successfully:", userInfo);
  //  } catch (error) {
  //    console.error("Failed to register user:", error);
  //  }
  // };

  // const handleLogin = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch(`${apiUrl}/api/auth/login`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ utilizador, password }),
  //     });

  //     const contentType = response.headers.get("content-type");

  //     if (contentType && contentType.includes("application/json")) {
  //       const data = await response.json();

  //       if (response.ok) {
  //         // Store the JWT token in localStorage
  //         localStorage.setItem("token", data.token);

  //         // Assuming `data` contains the role or ID
  //         const userRole = data.role; // e.g., admin
  //         const RHID = data.user.RHID; // e.g., 7002

  //         toast.success("Login successful!");

  //         // Check user role or ID and navigate accordingly
  //         if (RHID === 7002) {
  //           // Redirect to the 'cadastre' page for admin or user with ID 7002
  //           setTimeout(() => {
  //             navigate("/employee");
  //           }, 500);
  //         } else {
  //           // Otherwise, redirect to employee dashboard
  //           setTimeout(() => {
  //             navigate("/employee");
  //           }, 1000);
  //         }
  //       } else {
  //         toast.error(data.message || "Login failed. Please try again.");
  //       }
  //     } else {
  //       toast.error("An unexpected error occurred. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //     toast.error("An unexpected error occurred. Please try again.");
  //   }
  // };
  //  const handleLogin = async (username, password) => {
  //    if (navigator.onLine) {
  //      // Online login
  //      try {
  //        const response = await fetch(`${apiUrl}/api/auth/login`, {
  //          method: "POST",
  //          headers: { "Content-Type": "application/json" },
  //          body: JSON.stringify({ utilizador, password }),
  //        });
  //        const data = await response.json();

  //        if (data.success) {
  //          // Save user data to localStorage for future offline access
  //          localStorage.setItem("token", data.token);
  //          localStorage.setItem("userInfo", JSON.stringify(data.user));
  //          alert("Logged in online successfully!");
  //        } else {
  //          alert("Invalid credentials!");
  //        }
  //      } catch (error) {
  //        console.error("Login failed:", error);
  //        alert("Unable to connect to the server.");
  //      }
  //    } else {
  //      // Offline login (check if user data exists in localStorage)
  //      const storedUser = localStorage.getItem("userInfo");
  //      const storedToken = localStorage.getItem("token");

  //      if (storedUser && storedToken) {
  //        const savedUser = JSON.parse(storedUser);

  //        // Optionally, verify if the offline credentials match
  //        if (savedUser.username === username) {
  //          alert("Logged in offline successfully!");
  //        } else {
  //          alert("Offline login failed: User not found.");
  //        }
  //      } else {
  //        alert(
  //          "No offline data available. Please connect to the internet to log in for the first time."
  //        );
  //      }
  //    }
  //  };

  // // Modify the offline login check
  // useEffect(() => {
  //   checkOfflineLogin();
  // }, []);

  // const checkOfflineLogin = () => {
  //   const storedToken = localStorage.getItem("token");
  //   if (storedToken) {
  //     const decodedToken = jwtDecode(storedToken);
  //     setUtilizador(decodedToken.utilizador);

  //     // Redirect to employee page if the user is already logged in offline
  //     if (decodedToken.utilizador) {
  //       navigate("/employee");
  //     }
  //   }
  // };
  // let faceio;

  // useEffect(() => {
  //   // Initialize FaceIO once on component mount
  //   const initFaceIO = () => {
  //     if (typeof faceIO !== "undefined") {
  //       try {
  //         // eslint-disable-next-line react-hooks/exhaustive-deps
  //         faceio = new faceIO("fioa8654"); // Replace with your actual FACEIO App ID
  //         console.log("FaceIO initialized successfully");
  //       } catch (error) {
  //         console.error("Failed to initialize FaceIO:", error);
  //         toast.error("Failed to initialize FaceIO");
  //       }
  //     }
  //   };
  //   initFaceIO();
  // }, []);
  // // Function to enroll a new user
  // const enrollUser = async () => {
  //   try {
  //     const userInfo = await faceio.enroll({
  //       locale: "auto",
  //       payload: {
  //         email: "user@example.com", // Example payload, replace with actual data as needed
  //       },
  //     });
  //     console.log("User enrolled successfully:", userInfo);
  //     toast.success("User enrolled successfully!");
  //   } catch (error) {
  //     console.error("Failed to enroll user:", error);
  //     handleFaceIOError(error);
  //   }
  // };

  // // Function to authenticate an existing user
  // const authenticateUser = async () => {
  //   try {
  //     const userData = await faceio.authenticate({
  //       locale: "auto",
  //     });
  //     console.log("User authenticated successfully:", userData);
  //     toast.success("User authenticated successfully!");

  //     // Redirect to the dashboard or employee page upon successful authentication
  //     navigate("/employee");
  //   } catch (error) {
  //     console.error("Failed to authenticate user:", error);
  //     handleFaceIOError(error);
  //   }
  // };

  // // Error handling function
  // const handleFaceIOError = (errCode) => {
  //   const errorMessages = {
  //     PERMISSION_REFUSED: "Access to the camera was denied",
  //     NO_FACES_DETECTED: "No faces detected. Please try again.",
  //     UNRECOGNIZED_FACE: "Face not recognized",
  //     MANY_FACES: "Multiple faces detected. Only one face is allowed.",
  //     FACE_DUPLICATION: "Face already enrolled",
  //     MINORS_NOT_ALLOWED: "Minors are not allowed to enroll",
  //     PAD_ATTACK: "Spoof attack detected",
  //     FACE_MISMATCH: "Face mismatch",
  //     WRONG_PIN_CODE: "Incorrect PIN code",
  //     PROCESSING_ERR: "Server error. Please try again later.",
  //     UNAUTHORIZED: "Unauthorized operation. Check your App ID.",
  //     TERMS_NOT_ACCEPTED: "Terms and conditions not accepted",
  //     UI_NOT_READY: "FaceIO widget not ready",
  //     SESSION_EXPIRED: "Session expired",
  //     TIMEOUT: "Operation timed out",
  //     TOO_MANY_REQUESTS: "Too many requests. Try again later.",
  //     EMPTY_ORIGIN: "Request header missing origin",
  //     FORBIDDDEN_ORIGIN: "Origin not allowed to use FaceIO",
  //     FORBIDDDEN_COUNTRY: "Country not allowed to use FaceIO",
  //     SESSION_IN_PROGRESS: "Another session is in progress",
  //     NETWORK_IO: "Network connection error",
  //     DEFAULT: "An unknown error occurred",
  //   };

  //   const message = errorMessages[errCode] || errorMessages.DEFAULT;
  //   toast.error(message);
  //   console.error("FaceIO Error:", message);
  // };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Check if user is offline
    if (!navigator.onLine) {
      // Offline mode: Validate based on stored data in localStorage
      const storedUser = JSON.parse(localStorage.getItem("userInfo"));
      const storedToken = localStorage.getItem("token");

      if (storedUser && storedToken) {
        // Verify username and password from stored user data
        if (
          storedUser.utilizador === utilizador &&
          storedUser.password === password // Direct comparison for now
        ) {
          toast.success("Logged in offline successfully!");
          navigate("/employee");
        } else {
          toast.error("Offline login failed: Invalid credentials.");
        }
      } else {
        toast.error("No offline data available. Please log in online first.");
      }
    } else {
      // Online mode: Proceed with normal API login
      try {
        const response = await fetch(`${apiUrl}/api/auth/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ utilizador, password }),
        });

        if (!response.ok) throw new Error("Invalid credentials");

        const data = await response.json();

        // Store data locally for future offline use
        localStorage.setItem("token", data.token);
        localStorage.setItem("userInfo", JSON.stringify(data.user));

        // toast.success("Logged in successfully!");
        alert("Logged in successfully!");
        console.log("Token and user info stored successfully"); // Debugging step
        console.log("Navigating to /employee"); // Debugging step

        setTimeout(() => navigate("/employee", { replace: true }), 500); // Slight delay
      } catch (error) {
        toast.error("Unable to connect to the server or invalid credentials.");
      }
    }
  };
  return (
    <>
      {/* ToastContainer is required to display toasts */}
      <ToastContainer />
      <div className="mx-auto relative flex items-center justify-center bg-gray-100">
        <div className="bg-white shadow-md overflow-hidden w-full">
          <div className="relative">
            <img
              src={loginIamge}
              alt="Cover"
              className="w-full h-full object-cover"
            />
            <div
              className="absolute top-0 right-0   mt-4 mr-4 flex items-center bg-transparent
             p-2 rounded-full "
            >
              <LanguageSelector
                language={language}
                onChange={handleLanguageChange}
              />
            </div>
          </div>

          <div className="absolute top-[288px] left-0 right-0 bg-white p-6 rounded-3xl mb-10 ">
            <div className="flex items-center justify-center mt-6">
              <h1 className="text-[#373737] text-[24px] font-medium">
                {t("login")}
              </h1>
            </div>
            <h2 className="text-center text-[14px] font-normal text-[#7D7D7D] mb-4">
              {t("welcomeBack")}
            </h2>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label className="block text-gray-600 mb-2 font-semibold">
                  {t("username")}
                </label>
                <div className="flex items-center border bg-[#F8F8F8] border-[#D9D9D9] rounded-md">
                  <span className="px-3">
                    <div className="flex gap-3">
                      <img src={person} alt="" />
                      <img src={line} alt="" />
                    </div>
                  </span>
                  {/* <input
                    type="text"
                    placeholder={t("Enter your username!")}
                    className="flex-1 py-2.5 px-4 text-gray-700  border-[#D9D9D9] bg-[#F8F8F8] focus:outline-none 
                    focus:ring-2
                     focus:ring-[#D9D9D9] rounded-md"
                    value={utilizador}
                    onChange={(e) => setUtilizador(e.target.value)}
                    // required
                  /> */}
                  <input
                    type="text"
                    name="utilizador"
                    placeholder={t("Enter your username!")}
                    className="flex-1 py-2.5 px-4 text-gray-700 border-[#D9D9D9] bg-[#F8F8F8] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-md"
                    value={utilizador}
                    onChange={(e) => setUtilizador(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 mb-2 font-semibold">
                  {t("password")}
                </label>
                <div className="flex items-center border bg-[#F8F8F8] border-[#D9D9D9] rounded-md">
                  <span className="px-3">
                    <div className="flex gap-3">
                      <img src={passwordIcon} alt="" />
                      <img src={line} alt="" />
                    </div>
                  </span>
                  {/* <input
                    type="password"
                    placeholder="************"
                    className="flex-1 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2
                    border-[#D9D9D9] bg-[#F8F8F8] focus:ring-[#D9D9D9] rounded-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // required
                  /> */}
                  <input
                    type="password"
                    name="password"
                    placeholder="************"
                    className="flex-1 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 border-[#D9D9D9] bg-[#F8F8F8] focus:ring-[#D9D9D9] rounded-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              {error && (
                <div className="text-red-500 text-center mb-4">{error}</div>
              )}
              <div className="flex items-end justify-end mb-6">
                <a
                  href="#"
                  className="text-sm text-[#8AB53E] hover:underline font-semibold"
                >
                  {t("forgotPassword")}
                </a>
              </div>
              <div className="flex">
                {/* <button
                  type="submit"
                  className="w-full py-4 px-2 bg-[#8AB53E] text-white rounded-md
                 hover:bg-[#8AB53E] transition duration-200"
                >
                  <Link to="/employee"> LOGIN</Link>
                </button> */}

                <button
                  type="submit"
                  className="w-full py-4 px-2 bg-[#8AB53E] text-white rounded-md
                       hover:bg-[#8AB53E] transition duration-200"
                >
                  {t("login")}
                </button>
                <div
                  // onClick={handleFaceLogin}
                  className="bg-[#EEEEEE] rounded-md p-3 ml-5"
                >
                  {/* <img src={loginButton} alt="" className="w-[46px]" /> */}
                </div>
              </div>
              {/* <div className="flex justify-center items-center mt-4">
                <span className="text-[#7D7D7D] text-[14px]">
                  {t('noAccount')}
                </span>
                <a
                  href="/sign-up"
                  className="text-[#8AB53E] font-semibold hover:underline ml-2 "
                >
                  {t('signup')}
                </a>
              </div> */}
            </form>
            {/* <button onClick={enrollUser}>Enroll New User</button>
            <button onClick={authenticateUser}>Authenticate User</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
