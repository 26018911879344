// AddAbsence.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterNavigation from "../FooterNavigation";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";

const AddAbsence = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    CD_AUSENCIA: "",
    DT_ADMISSAO: "", // Added DT_ADMISSAO
    DT_INI: "",
    DT_FIM: "",
    TIPO_REGISTO: "",
    TP_AUSENCIA: "",
    ESTADO: "",
    TP_ORIGEM: "",
    NR_CALENDARIO: "",
    NR_MINUTOS: "",
    MIN_TOTAL: "",
    MIN_NOCT: "",
    MIN_MEDIO_N_UTIL: "",
    REGISTO_ANTERIOR: "",
    REGISTO_POSTERIOR: "",
    DT_DOC_JUST: "",
    ID_DOC_JUST: "",
    OBS: "",
  });

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.post(`${apiUrl}/api/absences/add-absence`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Absence added successfully!");
      navigate("/absenses"); // Navigate back to calendar or main page
    } catch (error) {
      console.error("Error adding absence:", error);
      alert("Failed to add absence");
    }
  };

  return (
    <>
      <CommonHeaderWithMenu title="Register a Absense" />
      <div className="container mx-auto p-4 mb-20">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block font-medium">Absence Code</label>
            <input
              type="text"
              name="CD_AUSENCIA"
              value={formData.CD_AUSENCIA}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Admission Date</label>
            <input
              type="date"
              name="DT_ADMISSAO"
              value={formData.DT_ADMISSAO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Start Date & Time</label>
            <input
              type="datetime-local"
              name="DT_INI"
              value={formData.DT_INI}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">End Date & Time</label>
            <input
              type="datetime-local"
              name="DT_FIM"
              value={formData.DT_FIM}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Type of Register</label>
            <input
              type="text"
              name="TIPO_REGISTO"
              value={formData.TIPO_REGISTO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Absence Type</label>
            <input
              type="text"
              name="TP_AUSENCIA"
              value={formData.TP_AUSENCIA}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Status</label>
            <input
              type="text"
              name="ESTADO"
              value={formData.ESTADO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Origin Type</label>
            <input
              type="text"
              name="TP_ORIGEM"
              value={formData.TP_ORIGEM}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Calendar Number</label>
            <input
              type="text"
              name="NR_CALENDARIO"
              value={formData.NR_CALENDARIO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Minutes</label>
            <input
              type="number"
              name="NR_MINUTOS"
              value={formData.NR_MINUTOS}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Total Minutes</label>
            <input
              type="number"
              name="MIN_TOTAL"
              value={formData.MIN_TOTAL}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Night Minutes</label>
            <input
              type="number"
              name="MIN_NOCT"
              value={formData.MIN_NOCT}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">
              Average Non-Util Minutes
            </label>
            <input
              type="number"
              name="MIN_MEDIO_N_UTIL"
              value={formData.MIN_MEDIO_N_UTIL}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Previous Record</label>
            <input
              type="text"
              name="REGISTO_ANTERIOR"
              value={formData.REGISTO_ANTERIOR}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Next Record</label>
            <input
              type="text"
              name="REGISTO_POSTERIOR"
              value={formData.REGISTO_POSTERIOR}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Document Date</label>
            <input
              type="date"
              name="DT_DOC_JUST"
              value={formData.DT_DOC_JUST}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Document ID</label>
            <input
              type="text"
              name="ID_DOC_JUST"
              value={formData.ID_DOC_JUST}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="block font-medium">Observations</label>
            <textarea
              name="OBS"
              value={formData.OBS}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-[#8AB53E] text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </form>
      </div>
      <FooterNavigation />
    </>
  );
};

export default AddAbsence;
