import React, { useEffect, useState } from "react";
import { FaAddressCard, FaChartLine, FaCoins, FaFileAlt, FaHome, FaHourglassStart, FaPaperPlane, FaTimes } from "react-icons/fa";
import {
  FaCircleCheck,
  FaDochub,
  FaSquareRss,
} from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode correctly
import { useTranslation } from "react-i18next";

const CommonHeaderWithMenu = ({ title }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [rhid, setRhid] = useState(null); // State for RHID
  const [menuItems, setMenuItems] = useState([]); // State for menu items
  const [userRole, setUserRole] = useState(null); // State for user role
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Decode JWT to get user details
  useEffect(() => {
    const fetchUserData = () => {
      const token = localStorage.getItem("token"); // Adjust the key if necessary
      if (token) {
        const decodedToken = jwtDecode(token);
        // console.log(decodedToken); // Log the decoded token
        const userRhid = decodedToken.RHID; // Assuming your token has 'rhid'
        const role = decodedToken.userId; // Assuming your token has 'role'
        setRhid(userRhid);
        setUserRole(role);

        // Set menu items based on role
        const menuData = {
          7002: [
            { icon: <FaHome />, label: t("Home"), link: "/employee" },
            { icon: <FaAddressCard />, label: t("Cadastre"), link: "/cadstre" },
            { icon: <FaFileAlt />, label: t("Outputs"), link: "/outputs" },
            {
              icon: <FaHourglassStart />,
              label: t("Time Management"),
              link: "/communication",
            },
            {
              icon: <FaCircleCheck />,
              label: t("Approvals"),
              link: "/admin-approval",
            },
            {
              icon: <FaPaperPlane />,
              label: t("Delegations"),
              link: "/admin-delegations",
            },
            {
              icon: <FaChartLine />,
              label: t("KPIs"),
              link: "/admin-kpis",
            },
          ],
          7098: [
            { icon: <FaCoins />, label: t("Expenses"), link: "/expanses" },
            { icon: <FaAddressCard />, label: t("Cadastre"), link: "/cadstre" },
            { icon: <FaFileAlt />, label: t("Outputs"), link: "/outputs" },
            {
              icon: <FaSquareRss />,
              label: t("Communication"),
              link: "/communication",
            },
            {
              icon: <FaHourglassStart />,
              label: t("Time Management"),
              link: "/communication",
            },
            {
              icon: <FaDochub />,
              label: "Doc.Request",
              link: "/communication",
            },
          ],
        };

        setMenuItems(menuData[userRhid] || []);
        // console.log(menuData[role]);
        // Log the menu items based on role
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <div
        className={`${isSticky ? "fixed top-0 left-0 right-0 z-50 shadow-md" : ""
          } bg-white p-4 transition-all duration-300 ease-in-out`}
        style={isSticky ? { marginBottom: "64px" } : {}} // Adjust margin for sticky header
      >
        <div className="flex items-center justify-between">
          <div
            className="flex items-center justify-center w-10 h-10 bg-transparent cursor-pointer"
            onClick={toggleMenu}
          >
            <FiMenu className="text-xl text-gray-600" />
          </div>

          <h1 className="text-xl font-semibold text-[#373737]">{title}</h1>

          <div className="relative flex items-center justify-center">
            <h1 className="text-black ml-[20px]">.</h1>
          </div>
        </div>
      </div>

      {/* Menu */}
      {menuOpen && (
        <div
          style={{
            transform: menuOpen ? "translateX(0)" : "translateX(-100%)",
            transition: "transform 0.3s ease",
          }}
          className="fixed inset-y-0 left-0 w-3/4 max-w-xs bg-gradient-to-b
           bg-[#FFFFFF] text-black shadow-lg z-50 p-6 rounded-tr-lg rounded-br-lg overflow-y-auto"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-black">
              {rhid === 7002 ? "Admin" : "Employee"}
            </h2>
            <FaTimes className="text-xl text-black" onClick={toggleMenu} />
          </div>
          <ul className="space-y-6">
            {menuItems.map((item, index) => (
              <li key={index} className="flex items-center">
                {item.icon} {/* Assuming the icon is a component */}
                <Link to={item.link} className="text-black text-lg ml-3">
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Overlay */}
      {menuOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
};

export default CommonHeaderWithMenu;
