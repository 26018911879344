import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const DelegationModal = ({
  isVisible,
  onClose,
  onSubmit,
  title,
  fields = [],
  delegation = null, // Added delegation prop
}) => {
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  if (!isVisible) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const isFormComplete = fields.every(
      (field) => formData[field.name] && formData[field.name].trim() !== ""
    );

    if (isFormComplete) {
      onSubmit(formData);
      setFormData({}); // Clear form after submission
      onClose();
    } else {
      toast.warning("Please fill out all required fields.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 mb-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-[80vh]">
        <h2 className="text-lg font-bold mb-4">{t(title)}</h2>

        {/* Display selected delegation info */}
        {delegation && (
          <div className="mb-4 p-4 bg-gray-100 rounded-lg">
            <h3 className="font-semibold mb-2">{t("Selected Delegation")}:</h3>
            <p>
              <strong>Empresa:</strong> {delegation.EMPRESA}
            </p>
            <p>
              <strong>RHID Destino:</strong> {delegation.RHID}
            </p>
            <p>
              <strong>Perfil:</strong> {delegation.PERFIL}
            </p>
            <p>
              <strong>DT Início:</strong> {delegation.DT_INICIO}
            </p>
            {delegation.DT_FIM && (
              <p>
                <strong>DT Fim:</strong> {delegation.DT_FIM}
              </p>
            )}
            {delegation.OBS && (
              <p>
                <strong>Observations:</strong> {delegation.OBS}
              </p>
            )}
          </div>
        )}

        {fields.map((field, index) => (
          <div key={index} className="mb-4">
            <label className="block text-sm font-medium mb-2">
              {field.label}
            </label>
            <input
              type={
                field.type === "datetime-local" ? "date" : field.type || "text"
              }
              name={field.name}
              value={formData[field.name] || ""}
              onChange={handleChange}
              placeholder={field.placeholder}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-400 text-white rounded mr-2 hover:bg-gray-500"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DelegationModal;
