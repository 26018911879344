import { useTranslation } from "react-i18next";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";

const Profile = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    NOME: '', // name
    GENERO: '', // gender
    EMAIL: '',
    TELEMOVEL_1: '', // telephone
    ESTADO_CIVIL: '', // merit status
    DT_NASCIMENTO: '' // date of birth
  });
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleUpdateForm = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/api/cadastre/user/data`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success(t('Record updated successfully'));
      } else {
        toast.error(t('Something went wrong'));
      }
    } catch (error) {
      toast.error(t('Something went wrong'));
      console.log(error);
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData({
      ...formData,
      [name]: value
    });
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/cadastre/user/data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setFormData(data.userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="p-5 border-b flex items-center gap-x-5">
        <Link to="/settings" > <FaAngleLeft /> </Link>
        <span className="text-gray-500 font-semibold text-lg">{t('Profile')}</span>
      </div>
      <div className="px-5 pt-4">
        <div className="flex flex-col justify-center p-2 gap-y-4">
          <div className="flex flex-col gap-y-1">
            <label className="text-gray-700 font-medium">{t('Name')}</label>
            <input type="text" className="w-full p-2 rounded border border-gray-400" name="NOME" value={formData.NOME} onChange={handleChange} />
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-gray-700 font-medium">{t('Email')}</label>
            <input type="email" className="w-full p-2 rounded border border-gray-400" name="EMAIL" value={formData.EMAIL} onChange={handleChange} />
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-gray-700 font-medium">{t('Mobile Number')}</label>
            <input type="text" className="w-full p-2 rounded border border-gray-400" name="TELEMOVEL_1" value={formData.TELEMOVEL_1} onChange={handleChange} />
          </div>

          <div className="flex flex-col gap-y-1">
            <label className="text-gray-700 font-medium">{t('Gender')}</label>
            <div className="flex">
              <input type="radio" name="gender" id="male" value="M" onChange={handleChange} className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" checked={formData.GENERO === 'Masculino'} />
              <label for="male" className="text-sm text-gray-500 ms-2 ">{t('Male')}</label>
            </div>

            <div className="flex">
              <input type="radio" name="gender" id="female" value="F" onChange={handleChange} className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" checked={formData.GENERO === 'Feminino'} />
              <label for="female" className="text-sm text-gray-500 ms-2 ">{t('Female')}</label>
            </div>
          </div>

          <div className="flex flex-col gap-y-1">
            <label className="text-gray-700 font-medium">{t('Marital Status')}</label>
            <div className="flex">
              <input type="radio" name="marital_status" className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="single" onChange={handleChange} checked={formData.ESTADO_CIVIL === 'Solteiro'} />
              <label for="single" className="text-sm text-gray-500 ms-2 ">{t('Single')}</label>
            </div>

            <div className="flex">
              <input type="radio" name="marital_status" className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="married" onChange={handleChange} checked={formData.ESTADO_CIVIL === 'Casado'} />
              <label for="married" className="text-sm text-gray-500 ms-2">{t('Married')}</label>
            </div>

            <div className="flex">
              <input type="radio" name="marital_status" className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="divorced" onChange={handleChange} checked={formData.ESTADO_CIVIL === 'Divorciado'} />
              <label for="divorced" className="text-sm text-gray-500 ms-2 ">{t('Divorced')}</label>
            </div>
          </div>

          <div className="block mt-5">
            <button className="w-full py-3 bg-emerald-500 text-white rounded font-semibold" onClick={handleUpdateForm}>{t('Save Changes')}</button>
          </div>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
}

export default Profile;