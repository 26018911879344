import React, { createContext, useState, useContext } from "react";

export const ToggleContext = createContext();

export const ToggleProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <ToggleContext.Provider value={{ toggle, setToggle }}>
      {children}
    </ToggleContext.Provider>
  );
};

// Custom hook to use toggle context
export const useToggle = () => useContext(ToggleContext);
