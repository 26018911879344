import React from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { useTranslation } from "react-i18next";

const KPIS = () => {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeaderWithMenu title="KPIS" />
      <h1 className="text-center py-10">{t('No data available')}</h1>
      <FooterNavigation />
    </>
  );
};

export default KPIS;
