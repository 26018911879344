import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const OnboardingData = () => {
  const [onboardingData, setOnboardingData] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchOnboardingData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/rolebased/onboarding`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setOnboardingData(data.onboardingData);
      } catch (error) {
        console.error("Error fetching onboarding data:", error);
      }
    };

    fetchOnboardingData();
  }, []);

  const toggleAccordion = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Onboarding Data
        </h2>
        {onboardingData.length === 0 ? (
          <p>No onboarding data available.</p>
        ) : (
          <div className="bg-white shadow-md rounded-lg">
            {onboardingData.map((data, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full flex justify-between items-center px-6 py-4 focus:outline-none"
                >
                  <span className="font-medium text-lg text-gray-700">
                    {data.TITULO}
                  </span>
                  {expanded === index ? (
                    <FaChevronUp className="h-6 w-6 text-gray-500" />
                  ) : (
                    <FaChevronDown className="h-6 w-6 text-gray-500" />
                  )}
                </button>
                {expanded === index && (
                  <div className="p-6 bg-gray-100">
                    <p className="text-sm text-gray-500 mb-2">
                      <strong>Date:</strong>{" "}
                      {new Date(data.DATA).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500 mb-2">
                      <strong>State:</strong> {data.ESTADO}
                    </p>
                    <div className="mb-4">
                      <p className="text-gray-700">
                        <strong>Description Type</strong>:{data.DESCRICAO?.type}
                      </p>
                    </div>
                    <p className="text-sm text-gray-500">
                      <strong>Inserted by:</strong> {data.INSERTED_BY}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Last Updated:</strong>{" "}
                      {new Date(data.DT_UPDATED).toLocaleString()}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default OnboardingData;
