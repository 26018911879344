import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import FooterNavigation from "../FooterNavigation";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import UpdateExpenseForm from "./UpdateExpenseForm"; // Import the form component
import { toast, ToastContainer } from "react-toastify"; // Import toast notifications
import "react-toastify/dist/ReactToastify.css"; // Import Toast styles

const UpdateExpenseDetails = () => {
  const { id } = useParams(); // Extract expense ID from URL
  const [expense, setExpense] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false); // Control form visibility
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchExpenseDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/expenses/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setExpense(data.expense); // Set the fetched expense details
        } else {
          console.error("Failed to fetch expense details");
        }
      } catch (error) {
        console.error("Error fetching expense details:", error);
      }
    };

    fetchExpenseDetails();
  }, [id, apiUrl]);

  const handleUpdateClick = () => {
    setIsFormVisible(!isFormVisible); // Toggle form visibility
  };

  if (!expense) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-600 text-xl">Loading...</div>
      </div>
    ); // Show loading state while fetching
  }

  return (
    <>
      <CommonHeaderWithMenu title="Expense Details" />
      <div className="mb-20">
        <div className="  p-6 bg-gray-100  ">
          <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Expense Details
            </h2>
            <div className="text-gray-700">
              <table className="min-w-full whitespace-nowrap bg-white">
                <tr className="border-t ">
                  <th scope="col" className="border-x ps-4 py-4 text-start w-44">Company</th>
                  <td className="border-x ps-4 py-4">{expense.EMPRESA}</td>
                </tr>

                <tr className="border-t ">
                  <th scope="col" className="border-x ps-4 py-4 text-start w-44">Total Expense</th>
                  <td className="border-x ps-4 py-4">{expense.TOTAL_DESPESA}</td>
                </tr>

                <tr className="border-t">
                  <th scope="col" className="border-x ps-4 py-4 text-start w-44">Inserted Date</th>
                  <td className="border-x ps-4 py-4">
                    {new Date(expense.DT_INSERTED).toLocaleDateString()}
                  </td>
                </tr>

                <tr className="border-t">
                  <th scope="col" className="border-x ps-4 py-4 text-start">Action</th>
                  <td className="border-x ps-4 py-4">
                    <Link to={`/expense/${id}/update`} className="bg-[#8AB53E] text-white px-2 py-2 rounded"> Edit</Link>
                  </td>
                </tr>
              </table>
            </div>

            {/* Button to show the update form */}
            {/* <button
              onClick={handleUpdateClick}
              className="mt-6 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              {isFormVisible ? "Cancel Update" : "Update Expense"}
            </button> */}
            {/* <Link
              to={`/expense/${id}/update`}
              className="mt-6 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              Update Expense
            </Link> */}
            {/* Conditionally render the UpdateExpenseForm when the button is clicked */}
            {isFormVisible && (
              <Link
                to={`/expense/${id}/update`}
                className="mt-6 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
              ></Link>
            )}

            {/* <Link
              to="/expanses"
              className="block mt-4 text-blue-500 hover:underline"
            >
              Go Back to Expenses
            </Link> */}
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Toast container for notifications */}
      <FooterNavigation />
    </>
  );
};

export default UpdateExpenseDetails;
