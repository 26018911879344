import React from "react";

import { RouterProvider } from "react-router-dom";
import router from "./routes/Route";
import InstallPromptHandler from "./component/InstallPromptHandler";
import { ToastContainer } from "react-toastify";
import { LanguageProvider } from "./context/LanguageProvider";
import { NetworkProvider } from "./context/OfflineServiceProvider";
import { AuthProvider } from "./utils/AuthContext";
import { ToggleProvider } from "./context/ToggleProvider";

function App() {
  return (
    <>
      {/* <NetworkProvider> */}
      <ToggleProvider>
        <AuthProvider>
          <LanguageProvider>
            <InstallPromptHandler />
            <RouterProvider router={router}></RouterProvider>
            <ToastContainer />
          </LanguageProvider>
        </AuthProvider>
      </ToggleProvider>
      {/* </NetworkProvider> */}
    </>
  );
}

export default App;
