import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import Calender2 from "../../assets/images/calendar-2.png";
import Calender from "../../assets/images/calendar.png";
import Calender1 from "../../assets/images/calendar1.png";
import Category from "../../assets/images/category.png";
import First from "../../assets/images/First.png";
import Last from "../../assets/images/Last.png";
import menuLeft from "../../assets/images/menu-left.png";
import Next from "../../assets/images/Next.png";
import polygon from "../../assets/images/Polygon.png";
import Prev from "../../assets/images/Prev.png";
import search from "../../assets/images/search-normal.png";
import taskSquare from "../../assets/images/task-square.png";
import FooterNavigation from "../FooterNavigation";
import { Link } from "react-router-dom";
import { FaMagnifyingGlass } from "react-icons/fa6";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import axios from "axios";
import Calendar from "./Calendar";
import { useTranslation } from "react-i18next";

const Absences = () => {
  const [leaveBalances, setLeaveBalances] = useState({
    leaveBalance: 0,
    approved: 0,
    pending: 0,
    canceled: 0,
  });
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLeaveBalances = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/absences/leave-balances`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Adjust token retrieval as needed
            },
          }
        );
        setLeaveBalances(response.data);
      } catch (error) {
        console.error("Failed to fetch leave balances:", error);
      }
    };

    fetchLeaveBalances();
  }, []);
  return (
    <>
      <CommonHeaderWithMenu title={t('Calendar')} />
      <div className="p-4 mb-20">
        {/* Header Section */}
        {/* <div className=" bg-tranparent  rounded-b-lg"> */}
        {/* Top Section with Icons and Title */}
        {/* <div className="flex items-center justify-between mb-4"> */}
        {/* Menu Icon */}
        {/* <div
              className="flex items-center justify-center w-10 h-10
         bg-transparent"
            >
              <img src={menuLeft} alt="" />
            </div> */}

        {/* Title */}
        {/* <h1 className="text-xl font-semibold text-[#373737]"></h1> */}

        {/* Notification Icon */}
        {/* <div
              className="relative flex items-center justify-center 
           "
            > */}
        {/* <FaMagnifyingGlass className="mr-2 size-6" /> */}

        {/* <img src={search} alt="" className="h-7 w-7 mr-3 " /> */}
        {/* <FiBell className="text-xl text-gray-600" />
          <span className="absolute top-1 right-1 w-2.5 h-2.5 bg-green-400 rounded-full"></span> */}
        {/* </div> */}
        {/* </div> */}

        {/* Search Bar */}
        {/* </div> */}
        {/* <div className="flex justify-between items-center mb-10"> */}
        {/* Left Navigation Buttons */}
        {/* <div className="flex space-x-2 bg-[#F8F8F8] rounded-md border border-[#D9D9D9] p-2">
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={First} alt="" />
            </button>
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={Prev} alt="" />
            </button>
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={Next} alt="" />
            </button>
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={Last} alt="" />
            </button>
          </div> */}

        {/* Right Navigation Buttons */}
        {/* <div className="flex space-x-2 bg-[#F8F8F8] rounded-md border border-[#D9D9D9]  p-2">
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={Calender} alt="" />
            </button>
            <button className="p-1 text-green-600 hover:bg-gray-200 rounded">
              <img src={Calender2} alt="" />
            </button>
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={Calender1} alt="" />
            </button>
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={taskSquare} alt="" />
            </button>
            <button className="p-1 text-gray-600 hover:bg-gray-200 rounded">
              <img src={Category} alt="" />
            </button>
          </div> */}
        {/* </div> */}
        {/* Calendar Section */}
        <div className="bg-white rounded-lg shadow-md p-4 mb-6">
          {/* Calendar Navigation */}
          {/* Calendar Navigation */}

          {/* Calendar */}

          <Calendar />
        </div>

        {/* Time Management Section */}
        <h1 className="text-[#373737] text-[16px] font-semibold mb-3">
          {t('Time Management')}
        </h1>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-[#EFF9FF] border border-[#38B4FF] rounded-xl p-4">
            <h3 className="text-sm font-semibold text-gray-700">
              {t('Leave')} <br />
              {t('Balance')}
            </h3>
            <p className="text-[20px] font-semibold text-[#38B4FF]">
              {leaveBalances.leaveBalance}
            </p>
          </div>
          <div className="bg-[#FFF2EC] border border-[#FFAE85] rounded-xl p-4">
            <h3 className="text-sm font-semibold text-gray-700">
              {t('Leave')} <br /> {t('Approved')}
            </h3>
            <p className="text-[20px] font-semibold text-[#FFAE85]">
              {leaveBalances.approved}
            </p>
          </div>
          <div className="bg-[#F2FFF3] border border-[#7AFF7D] rounded-xl p-4">
            <h3 className="text-sm font-semibold text-gray-700">
              {t('Leave')} <br /> {t('Pending')}
            </h3>
            <p className="text-[20px] font-semibold text-[#7AFF7D]">
              {leaveBalances.pending}
            </p>
          </div>
          <div className="bg-[#F8EFFF] border border-[#CA86FF] rounded-xl p-4 relative">
            <h3 className="text-sm font-semibold text-gray-700">
              {t('Leave')} <br /> {t('Canceled')}
            </h3>
            <p className="text-[20px] font-semibold text-[#CA86FF]">
              {leaveBalances.canceled}
            </p>
          </div>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default Absences;
