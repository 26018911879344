import React, { useState } from "react";
import FooterNavigation from "../FooterNavigation";
import menuLeft from "../../assets/images/menu-left.png";
import search from "../../assets/images/search-normal.png";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { v4 as uuidv4 } from "uuid"; // To generate unique ID
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";

const NewTickets = () => {
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [estado, setEstado] = useState("New");
  const [processs, setProcesss] = useState("");
  const [categoria, setCategoria] = useState("");
  const [prioSolicitada, setPrioSolicitada] = useState("");
  const [prioAtrib, setPrioAtrib] = useState("");
  const [requestType, setRequestType] = useState("");
  const [subject, setSubject] = useState("");
  const [descricao, setDescricao] = useState("");
  const [justifPrioAtrib, setJustifPrioAtrib] = useState("");
  const [lida, setLida] = useState("N");

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const uniqueId = uuidv4(); // Generate a unique ID for ID_ETICKET

    // Extract user information from localStorage
    const token = localStorage.getItem("token"); // Get the token from localStorage

    if (!token) {
      toast.error("User is not authenticated. Please login first.");
      return;
    }

    // Decode the token to get the user's ID
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId; // Extract the userId from the decoded token

    try {
      const response = await fetch(`${apiUrl}/api/etickets/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },
        body: JSON.stringify({
          ID_ETICKET: uniqueId,
          FROM_: userId, // Use the userId extracted from the token
          TO_: to,
          CC: cc,
          ESTADO: estado,
          PROCESSO: processs,
          CATEGORIA: categoria,
          PRIO_SOLICITADA: prioSolicitada,
          PRIO_ATRIB: prioAtrib,
          TIPO_PEDIDO: requestType,
          ASSUNTO: subject,
          DESCRICAO: descricao,
          JUSTIF_PRIO_ATRIB: justifPrioAtrib,
          LIDA: lida,
          INSERTED_BY: userId, // Use the userId extracted from the token
        }),
      });

      if (response.ok) {
        toast.success("Ticket created successfully!");
        navigate("/tickets");
        // Optionally, reset the form fields after successful submission
        // Reset form fields...
      } else {
        toast.error("Failed to create ticket");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      toast.error("An error occurred. Please try again.", error);
    }
  };

  return (
    <>
      <CommonHeaderWithMenu title="New E-Tickets" />
      <div className="p-4 mb-16">
        {/* Header */}
        {/* <div className=" bg-tranparent  rounded-b-lg"> */}
        {/* Top Section with Icons and Title */}
        {/* <div className="flex items-center justify-between mb-4"> */}
        {/* Menu Icon */}
        {/* <div
              className="flex items-center justify-center w-10 h-10
         bg-transparent"
            >
              <img src={menuLeft} alt="" />
            </div> */}

        {/* Title */}
        {/* <h1 className="text-xl font-semibold text-[#373737]">
              
            </h1> */}

        {/* Notification Icon */}
        {/* <div
              className="relative flex items-center justify-center 
           "
            > */}
        {/* <FaMagnifyingGlass className="mr-2 size-6" /> */}

        {/* <img src={search} alt="" className="h-7 w-7 mr-3 " /> */}
        {/* <FiBell className="text-xl text-gray-600" />
          <span className="absolute top-1 right-1 w-2.5 h-2.5 bg-green-400 rounded-full"></span> */}
        {/* </div> */}
        {/* </div> */}

        {/* Search Bar */}
        {/* </div> */}

        {/* Form Fields */}
        <div className="space-y-6">
          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              To
            </label>
            <div className="mt-1 relative">
              <select
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  7098
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  7078
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  admin
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              CC
            </label>
            <div className="mt-1 relative">
              <input
                type="text"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="CC (if any)"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Subject
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="block w-full px-3  py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="Lorem Ipsum"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Priority
            </label>
            <div className="mt-1 relative">
              <select
                value={prioSolicitada}
                onChange={(e) => setPrioSolicitada(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  Low
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Medium
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  High
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Assigned Priority
            </label>
            <div className="mt-1 relative">
              <input
                type="text"
                value={prioAtrib}
                onChange={(e) => setPrioAtrib(e.target.value)}
                className="block w-full px-3  py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="Assigned Priority"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Category
            </label>
            <div className="mt-1 relative">
              <select
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  Software
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Hardware
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Network
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Request Type
            </label>
            <div className="mt-1 relative">
              <select
                value={requestType}
                onChange={(e) => setRequestType(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  Support
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Maintenance
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Installation
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Process
            </label>
            <div className="mt-1 relative">
              <select
                value={processs}
                onChange={(e) => setProcesss(e.target.value)}
                className="block w-full px-3 py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
              >
                <option className="bg-white text-gray-700 hover:bg-[#b53e3e] hover:text-white">
                  Process 1
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Process 2
                </option>
                <option className="bg-white text-gray-700 hover:bg-[#8AB53E] hover:text-white">
                  Process 3
                </option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Justification for Assigned Priority
            </label>
            <div className="mt-1 relative">
              <input
                type="text"
                value={justifPrioAtrib}
                onChange={(e) => setJustifPrioAtrib(e.target.value)}
                className="block w-full px-3  py-3 border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                placeholder="Justification"
              />
            </div>
          </div>

          <div>
            <label className="block text-[16px] font-medium text-[#373737]">
              Content
            </label>
            <div className="mt-1 relative">
              <textarea
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                className="block w-full px-3 py-2  border bg-[#F8F8F8]
               border-[#D9D9D9] rounded-md  focus:outline-none
                focus:ring-[#D9D9D9] focus:border-[#D9D9D9] sm:text-sm"
                rows="3"
                placeholder="Enter your description here..."
              />
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="w-full py-3 bg-[#8AB53E] text-white font-semibold rounded-lg shadow-md"
          >
            Sent
          </button>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default NewTickets;
