/* eslint-disable jsx-a11y/anchor-is-valid */
import loginIamge from "../assets/images/loginbackground.png";
import signupImage from "../assets/images/signupimage.png";
import camera from "../assets/images/camera.png";
import person from "../assets/images/user.png";
import line from "../assets/images/line.png";
import passwordIcon from "../assets/images/lock.png";
import emailIocn from "../assets/images/sms-notification.png";
import LanguageSelector from "../component/LanguageSelector";
import { useState } from "react";
import { toast } from "react-toastify";
import { useLanguage } from "../context/LanguageProvider";
import { useNavigate } from "react-router-dom";
const labels = {
  pt: {
    title: "QUAD-HCM",
    username: "Utilizador",
    password: "Senha",
    email: "Email",
    confirmPassword: "Confirme a Senha",
    login: "Entrar",
    signup: "Inscrever-se",
    welcomeBack: "Bem-vindo de volta! Sentimos sua falta!",
    forgotPassword: "Esqueceu a senha?",
    noAccount: "Não tem uma conta?",
    enterCredentials: "Por favor, insira suas credenciais para continuar",
    alreadyHaveAccount: "Já tem uma conta?",
  },
  "en-uk": {
    title: "QUAD-HCM",
    username: "Username",
    password: "Password",
    email: "Email",
    confirmPassword: "Confirm Password",
    login: "Login",
    signup: "Sign Up",
    welcomeBack: "Welcome back! You've been missed!",
    forgotPassword: "Forgot Password?",
    noAccount: "Don't have an account?",
    enterCredentials: "Please enter your credentials to proceed",
    alreadyHaveAccount: "Already have an account?",
  },
  "en-us": {
    title: "QUAD-HCM",
    username: "Username",
    password: "Password",
    email: "Email",
    confirmPassword: "Confirm Password",
    login: "Login",
    signup: "Sign Up",
    welcomeBack: "Welcome back! You've been missed!",
    forgotPassword: "Forgot Password?",
    noAccount: "Don't have an account?",
    enterCredentials: "Please enter your credentials to proceed",
    alreadyHaveAccount: "Already have an account?",
  },
  de: {
    title: "QUAD-HCM",
    username: "Benutzername",
    password: "Passwort",
    email: "E-Mail",
    confirmPassword: "Passwort bestätigen",
    login: "Anmelden",
    signup: "Anmelden",
    welcomeBack: "Willkommen zurück! Wir haben dich vermisst!",
    forgotPassword: "Passwort vergessen?",
    noAccount: "Haben Sie kein Konto?",
    enterCredentials: "Bitte geben Sie Ihre Anmeldedaten ein, um fortzufahren",
    alreadyHaveAccount: "Haben Sie bereits ein Konto?",
  },
  es: {
    title: "QUAD-HCM",
    username: "Usuario",
    password: "Contraseña",
    email: "Correo electrónico",
    confirmPassword: "Confirmar Contraseña",
    login: "Iniciar sesión",
    signup: "Regístrate",
    welcomeBack: "¡Bienvenido de nuevo! ¡Te hemos echado de menos!",
    forgotPassword: "¿Olvidaste tu contraseña?",
    noAccount: "¿No tienes una cuenta?",
    enterCredentials: "Por favor, ingrese sus credenciales para continuar",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
  },
  fr: {
    title: "QUAD-HCM",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    email: "Email",
    confirmPassword: "Confirmez le mot de passe",
    login: "Connexion",
    signup: "S'inscrire",
    welcomeBack: "Bon retour! Vous nous avez manqué!",
    forgotPassword: "Mot de passe oublié?",
    noAccount: "Vous n'avez pas de compte?",
    enterCredentials: "Veuillez entrer vos informations pour continuer",
    alreadyHaveAccount: "Vous avez déjà un compte?",
  },
  it: {
    title: "QUAD-HCM",
    username: "Nome utente",
    password: "Password",
    email: "Email",
    confirmPassword: "Conferma Password",
    login: "Accedi",
    signup: "Iscriviti",
    welcomeBack: "Bentornato! Ci sei mancato!",
    forgotPassword: "Hai dimenticato la password?",
    noAccount: "Non hai un account?",
    enterCredentials: "Per favore, inserisci le tue credenziali per procedere",
    alreadyHaveAccount: "Hai già un account?",
  },
  ru: {
    title: "QUAD-HCM",
    username: "Имя пользователя",
    password: "Пароль",
    email: "Электронная почта",
    confirmPassword: "Подтвердите Пароль",
    login: "Войти",
    signup: "Зарегистрироваться",
    welcomeBack: "Добро пожаловать обратно! Мы скучали по вам!",
    forgotPassword: "Забыли пароль?",
    noAccount: "У вас нет учетной записи?",
    enterCredentials: "Пожалуйста, введите свои данные для продолжения",
    alreadyHaveAccount: "Уже есть аккаунт?",
  },
  zh: {
    title: "QUAD-HCM",
    username: "用户名",
    password: "密码",
    email: "电子邮件",
    confirmPassword: "确认密码",
    login: "登录",
    signup: "注册",
    welcomeBack: "欢迎回来！我们想念你！",
    forgotPassword: "忘记密码？",
    noAccount: "没有账户？",
    enterCredentials: "请输入您的凭据以继续",
    alreadyHaveAccount: "已有账号？",
  },
  ko: {
    title: "QUAD-HCM",
    username: "사용자 이름",
    password: "비밀번호",
    email: "이메일",
    confirmPassword: "비밀번호 확인",
    login: "로그인",
    signup: "가입하기",
    welcomeBack: "다시 오신 것을 환영합니다! 보고 싶었어요!",
    forgotPassword: "비밀번호를 잊으셨나요?",
    noAccount: "계정이 없으신가요?",
    enterCredentials: "계속하려면 자격 증명을 입력하세요",
    alreadyHaveAccount: "이미 계정이 있으신가요?",
  },
};

const Signup = () => {
  const { language, setLanguage } = useLanguage(); // Use context language and setLanguage
  // console.log(language);

  const handleLanguageChange = (value) => {
    setLanguage(value); // Update the language globally
    // console.log(value);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [utilizador, setUtilizador] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const handleSignup = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ utilizador, email, password }), // Include utilizador
        // Remove credentials: "include"
      });

      const data = await response.json();

      if (response.ok) {
        // Store the JWT token in localStorage
        localStorage.setItem("token", data.token);

        toast.success("Registration successful!");

        setTimeout(() => {
          navigate("/employee"); // Redirect to employee dashboard after signup
        }, 3000);
      } else {
        setError(data.message);
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error registering:", error);
      setError("An unexpected error occurred. Please try again.");
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const currentLabels = labels[language];

  return (
    <>
      <div className=" flex  items-center justify-center bg-gray-100 ">
        <div className="bg-white shadow-md  overflow-hidden  w-full">
          <div className="relative">
            <img
              src={loginIamge}
              alt="Cover"
              className="w-full h-full object-cover"
            />
            <div
              className="absolute top-0 right-0    mt-4 mr-4 flex items-center bg-transparent
             p-2 rounded-full "
            >
              <LanguageSelector
                language={language}
                onChange={handleLanguageChange}
              />
            </div>
          </div>
          <div
            className="absolute top-[148px] left-0 right-0  mt-8  bg-white  p-6
           rounded-3xl  mb-3 "
          >
            <div className=" inset-0  flex items-center justify-center mt-6">
              <h1 className="text-[#373737] text-[24px] font-medium">
                {currentLabels.signup}
              </h1>
            </div>
            <h2 className="text-center text-[14px] font-normal text-[#7D7D7D] mb-4">
              {currentLabels.enterCredentials}
            </h2>
            <div className="flex justify-center items-center my-5 mb-5">
              <div className="relative w-24 h-24 ">
                <img
                  src={signupImage}
                  alt="Profile"
                  className="w-24 h-24 rounded-full object-cover"
                />
                <div className="absolute bottom-0 right-0">
                  <div className="w-8 h-8 bg-[#8AB53E] rounded-full flex items-center justify-center">
                    <img
                      src={camera}
                      alt="Lock"
                      className="w-4 h-4 text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSignup}>
              <div className="mb-4">
                <label className="block text-gray-600 mb-2 font-semibold">
                  {currentLabels.username}
                </label>
                <div className="flex items-center border bg-[#F8F8F8] border-[#D9D9D9] rounded-md">
                  <span className="px-3">
                    <div className="flex gap-3">
                      <img src={person} alt="" />
                      <img src={line} alt="" />
                    </div>
                  </span>
                  <input
                    type="text"
                    placeholder="John Doe"
                    className="flex-1 py-2.5 px-4 text-gray-700  border-[#D9D9D9] bg-[#F8F8F8] focus:outline-none 
      focus:ring-2 focus:ring-[#D9D9D9] rounded-md"
                    value={utilizador}
                    onChange={(e) => setUtilizador(e.target.value)}
                    // required
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-600 mb-2 font-semibold">
                  {currentLabels.email}
                </label>
                <div className="flex items-center border bg-[#F8F8F8] border-[#D9D9D9] rounded-md">
                  <span className="px-3">
                    <div className="flex gap-3">
                      <img src={emailIocn} alt="" style={{ opacity: 0.6 }} />
                      <img src={line} alt="" />
                    </div>
                  </span>
                  <input
                    type="text"
                    placeholder="John@example.com"
                    className="flex-1 py-2.5 px-4 text-gray-700  border-[#D9D9D9] bg-[#F8F8F8] focus:outline-none 
                    focus:ring-2
                     focus:ring-[#D9D9D9] rounded-md"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    // required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 mb-2 font-semibold">
                  {currentLabels.password}
                </label>
                <div className="flex items-center border bg-[#F8F8F8] border-[#D9D9D9] rounded-md">
                  <span className="px-3">
                    <div className="flex gap-3">
                      <img src={passwordIcon} alt="" />
                      <img src={line} alt="" />
                    </div>
                  </span>
                  <input
                    type="password"
                    placeholder="************"
                    className="flex-1 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2
                    border-[#D9D9D9] bg-[#F8F8F8] focus:ring-[#D9D9D9] rounded-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 mb-2 font-semibold">
                  {currentLabels.confirmPassword}
                </label>
                <div className="flex items-center border bg-[#F8F8F8] border-[#D9D9D9] rounded-md">
                  <span className="px-3">
                    <div className="flex gap-3">
                      <img src={passwordIcon} alt="" />
                      <img src={line} alt="" />
                    </div>
                  </span>
                  <input
                    type="password"
                    placeholder="************"
                    className="flex-1 py-2 px-4 text-gray-700 focus:outline-none focus:ring-2
                    border-[#D9D9D9] bg-[#F8F8F8] focus:ring-[#D9D9D9] rounded-md"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    // required
                  />
                </div>
              </div>
              {error && (
                <div className="text-red-500 text-center mb-4">{error}</div>
              )}

              <div className="flex">
                <button
                  type="submit"
                  className="w-full py-4 px-2 bg-[#8AB53E] text-white rounded-md
  hover:bg-[#8AB53E] transition duration-200"
                >
                  {currentLabels.signup}
                </button>
              </div>
              <div className="flex justify-center items-center mt-4">
                <span className="text-[#7D7D7D] text-[14px]">
                  {currentLabels.alreadyHaveAccount}
                </span>
                <a
                  href="/"
                  className="text-[#8AB53E] font-semibold hover:underline ml-2"
                >
                  {currentLabels.login}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
