import React, { useEffect, useState } from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";

const ContractData = () => {
  const [contractData, setContractData] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/cadastre/contract-data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setContractData(data.contractData);
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    };

    fetchContractData();
  }, []);

  // Function to filter non-null fields
  const renderNonNullFields = (data) => {
    return Object.entries(data)
      .filter(([key, value]) => value !== null)
      .map(([key, value], index) => (
        <tr key={index} className="hover:bg-gray-100">
          <td className="px-6 py-4 border-b border-gray-300 capitalize">
            {key.replace(/_/g, " ")}
          </td>
          <td className="px-6 py-4 border-b border-gray-300">{value}</td>
        </tr>
      ));
  };

  return (
    <>
      <div className="p-4 space-y-6 max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md mt-6 border">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Contact Info
        </h1>
        {contractData.length === 0 ? (
          <p>No contract data available.</p>
        ) : (
          contractData.map((data, index) => (
            <div key={index} className="">
              <h2 className="text-xl font-semibold mb-4">
                Contract #{index + 1}
              </h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">
                        Field
                      </th>
                      <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderNonNullFields(data)}</tbody>
                </table>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default ContractData;
