import React, { useState } from "react";
import Homeicon from "../assets/images/IconHome.png";
import Carticon from "../assets/images/IconCart.png";
import Ticketicon from "../assets/images/ticket-2.png";
import Notification from "../assets/images/IconNotification.png";
import Profileicon from "../assets/images/IconProfile.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CartIcon from "../assets/images/Icon - Cart.png";
import ticket2 from "../assets/images/ticket2.png";
import homehastag from "../assets/images/home-hashtag.png";
import { toast } from "react-toastify";

const FooterNavigation = () => {
  const location = useLocation(); // To get the current URL path
  const navigate = useNavigate();

  const [showLogout, setShowLogout] = useState(false); // State to toggle logout button visibility
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent if any
      });

      if (response.ok) {
        // Remove the token from localStorage instead of Cookies
        localStorage.removeItem("token"); // Remove the token from localStorage
        setTimeout(() => {
          navigate("/"); // Redirect to login page after logout
        }, 1000);
      } else {
        toast.error("Logout failed"); // Show error toast if logout fails
      }
    } catch (error) {
      toast.error("An error occurred during logout"); // Show error toast if an error occurs
      console.error("An error occurred during logout", error);
    }
  };

  return (
    <div
      className="fixed bottom-0 left-0 right-0 bg-[#FFFFFF] p-4 flex justify-around 
      shadow-md rounded-b-2xl justify-items-center"
    >
      <Link to="/employee">
        <button
          className={
            location.pathname === "/employee"
              ? "text-[#8AB53E]"
              : "text-[#C1C1C1]"
          }
        >
          {/* <img src={Homeicon} alt="" className="text-[#8AB53E]" /> */}
          <img
            src={location.pathname === "/employee" ? Homeicon : homehastag}
            alt="Home"
          />
        </button>
      </Link>

      <Link to="/notification">
        <button
          className={
            location.pathname === "/notification" ? (
              <img src={CartIcon} alt="Cart" />
            ) : (
              "text-[#C1C1C1]"
            )
          }
        >
          <img
            src={location.pathname === "/notification" ? CartIcon : Carticon}
            alt="Cart"
          />
        </button>
      </Link>

      <Link to="/tickets">
        {" "}
        <button
          className={
            location.pathname === "/tickets"
              ? "text-[#8AB53E]"
              : "text-[#C1C1C1]"
          }
        >
          <img
            alt="Tickets"
            src={location.pathname === "/tickets" ? ticket2 : Ticketicon}
          />
        </button>
      </Link>

      <Link to="/absenses">
        <button
          className={
            location.pathname === "/notification"
              ? "text-[#8AB53E]"
              : "text-[#C1C1C1]"
          }
        >
          <img src={Notification} alt="Notifications" />
        </button>
      </Link>

      <Link to="/settings">
        <button
          className={
            location.pathname === "/profile"
              ? "text-[#8AB53E]"
              : "text-[#C1C1C1]"
          }
          onClick={() => setShowLogout(!showLogout)}
        >
          <img src={Profileicon} alt="Profile" />
        </button>
        {/* {showLogout && (
          <div className="absolute bottom-full right-0 mb-2 bg-white shadow-lg rounded p-2">
            <button
              className=" hover:text-white bg-red-500 py-2 px-4 rounded text-white "
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )} */}
      </Link>
      {/* <Link to="/expanses">
        <button
          className={
            location.pathname === "/profile"
              ? "text-[#8AB53E]"
              : "text-[#C1C1C1]"
          }
          onClick={() => setShowLogout(!showLogout)}
        >
          <img src={Profileicon} alt="Profile" />
        </button>
        {showLogout && (
          <div className="absolute bottom-full right-0 mb-2 bg-white shadow-lg rounded p-2">
            <button
              className=" hover:text-white bg-red-500 py-2 px-4 rounded text-white "
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </Link> */}
    </div>
  );
};

export default FooterNavigation;
