import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";

const UserOutputs = () => {
  const [outputsData, setOutputsData] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  useEffect(() => {
    const fetchOutputsData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("You need to be logged in to view this data.");
          return;
        }
        const response = await fetch(`${apiUrl}/api/user/outputs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch outputs data");
        }
        const data = await response.json();
        setOutputsData(data.outputsData);
      } catch (error) {
        console.error("Error fetching outputs data:", error);
        alert("An error occurred while fetching outputs data.");
      }
    };

    fetchOutputsData();
  }, [apiUrl]);

  // Function to generate and download the PDF for a single output
  const generatePDFSingle = (output) => {
    const doc = new jsPDF();
    doc.text(`Output Code: ${output.CD_OUTPUT}`, 14, 20);

    const tableColumn = ["Field", "Value"];
    const tableRows = [];

    // Add the output data to the table
    tableRows.push(["HEO Code", output.CD_HEO]);
    tableRows.push(["Year", output.ANO]);
    tableRows.push(["Month", output.MES]);
    tableRows.push([
      "Admission Date",
      new Date(output.DT_ADMISSAO).toLocaleDateString(),
    ]);
    tableRows.push(["Company", output.EMPRESA]);
    tableRows.push(["RHID", output.RHID]);
    tableRows.push(["Flex Data", output.FLEX_1_V1 || "N/A"]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      theme: "striped",
      styles: {
        fontSize: 10,
        cellPadding: 4,
      },
      headStyles: {
        fillColor: [41, 128, 185], // Blue header background
        textColor: [255, 255, 255], // White text
      },
    });

    doc.save(`output_${output.CD_OUTPUT}.pdf`);
  };

  // Function to generate and download a combined PDF for all outputs
  const generatePDFAll = () => {
    const doc = new jsPDF();
    doc.text("User Outputs", 14, 20);

    const tableColumn = [
      "Output Code",
      "HEO Code",
      "Year",
      "Month",
      "Admission Date",
      "Company",
      "RHID",
      "Flex Data",
    ];
    const tableRows = [];

    outputsData.forEach((output) => {
      const outputData = [
        output.CD_OUTPUT,
        output.CD_HEO,
        output.ANO,
        output.MES,
        new Date(output.DT_ADMISSAO).toLocaleDateString(),
        output.EMPRESA,
        output.RHID,
        output.FLEX_1_V1 || "N/A",
      ];
      tableRows.push(outputData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });

    doc.save("doc.request.pdf");
  };

  return (
    <div className="container mx-auto p-4">
      {/* <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
        Your Outputs
      </h2> */}
      {outputsData.length === 0 ? (
        <p>{t('No data available')}.</p>
      ) : (
        <div className="shadow-md rounded-lg items-center">
          {/* Button to Download All Outputs */}
          <div className="text-center mb-4">
            <button
              onClick={generatePDFAll}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {t('Download All as PDF')}
            </button>
          </div>

          {outputsData.map((output, index) => (
            <div key={index} className="border-b border-gray-200 p-6">
              <div className="flex justify-between items-center">
                <div className="w-full">
                  <table className="table-auto border-collapse w-full text-left">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 border bg-gray-200">{t('Field')}</th>
                        <th className="px-4 py-2 border bg-gray-200">{t('Value')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="px-4 py-2 border">{t('Output Code')}</td>
                        <td className="px-4 py-2 border">{output.CD_OUTPUT}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('HEO Code')}</td>
                        <td className="px-4 py-2 border">
                          {output.CD_HEO.length > 20
                            ? `${output.CD_HEO.substring(0, 20)}...`
                            : output.CD_HEO}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('Year')}</td>
                        <td className="px-4 py-2 border">{output.ANO}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('Month')}</td>
                        <td className="px-4 py-2 border">{output.MES}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('Admission Date')}</td>
                        <td className="px-4 py-2 border">
                          {new Date(output.DT_ADMISSAO).toLocaleDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('Company')}</td>
                        <td className="px-4 py-2 border">{output.EMPRESA}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('RHID')}</td>
                        <td className="px-4 py-2 border">{output.RHID}</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-2 border">{t('Flex Data')}</td>
                        <td className="px-4 py-2 border">
                          {output.FLEX_1_V1 && output.FLEX_1_V1.length > 20
                            ? `${output.FLEX_1_V1.substring(0, 20)}...`
                            : output.FLEX_1_V1 || "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" className="text-center py-4">
                          <button
                            onClick={() => generatePDFSingle(output)}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          >
                            {t('Download')} PDF
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserOutputs;
