// CalendarModal.js
import React from "react";

const CalendarModal = ({ isOpen, onClose, vacancys }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-lg font-semibold mb-4">Vacancies Details</h2>
        {vacancys.length > 0 ? (
          <ul>
            {vacancys.map((vacancy, index) => (
              <li key={index} className="mb-2">
                <strong>Name:</strong> {vacancy.employeeName} <br />
                <strong>Company:</strong> {vacancy.EMPRESA} <br />
                <strong>From:</strong> {vacancy.DT_INI} <br />
                <strong>To:</strong> {vacancy.DT_FIM} <br />
                <strong>Days:</strong>{" "}
                {vacancy.nrDiasCalend === null ? "N/A" : vacancy.nrDiasCalend}{" "}
                <br />
                <strong>Rubrica:</strong>{" "}
                {vacancy.cdRubrica === null ? "N/A" : vacancy.cdRubrica} <br />
                <strong>Minutes:</strong>{" "}
                {vacancy.nrMinCalend === null ? "N/A" : vacancy.nrMinCalend}{" "}
                min.
              </li>
            ))}
          </ul>
        ) : (
          <p>No Vacancies records found for this date.</p>
        )}
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CalendarModal;
