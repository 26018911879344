import React, { useEffect, useState } from "react";

const TimeManagementSection = ({ token }) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [timeManagement, setTimeManagement] = useState({
    today: [],
    tomorrow: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  useEffect(() => {
    const fetchTimeManagementData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/attendance/time-management`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch time management data");
        }

        const data = await response.json();
        setTimeManagement(data.timeManagement);
      } catch (error) {
        console.error("Error fetching time management data:", error);
      }
    };

    fetchTimeManagementData();
  }, [token]);

  const renderTimeSlot = (slots) => {
    if (slots.length === 0) {
      return <span className="text-[14px] mt-1 text-[#7D7D7D]">-</span>;
    }

    const slot = slots[0]; // Get only the first slot

    return (
      <span className="text-[14px] mt-1 text-[#7D7D7D]">
        {new Date(slot.DT_INI_DET).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}{" "}
        to{" "}
        {new Date(slot.DT_FIM_DET).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </span>
    );
  };

  return (
    <div className="bg-[#F8F8F8] border border-[#D9D9D9] p-4 rounded-xl mb-4">
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Today:</span>{" "}
        {renderTimeSlot(timeManagement.today)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Tomorrow:</span>{" "}
        {renderTimeSlot(timeManagement.tomorrow)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Monday:</span>{" "}
        {renderTimeSlot(timeManagement.monday)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Tuesday:</span>{" "}
        {renderTimeSlot(timeManagement.tuesday)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Wednesday:</span>{" "}
        {renderTimeSlot(timeManagement.wednesday)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Thursday:</span>{" "}
        {renderTimeSlot(timeManagement.thursday)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Friday:</span>{" "}
        {renderTimeSlot(timeManagement.friday)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Saturday:</span>{" "}
        {renderTimeSlot(timeManagement.saturday)}
      </p>
      <p className="flex justify-between ">
        <span className="text-[#373737] font-semibold">Sunday:</span>{" "}
        {renderTimeSlot(timeManagement.sunday)}
      </p>
    </div>
  );
};

export default TimeManagementSection;
