import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const DayDetails = () => {
  const [date, setDate] = useState("");
  const [dayDetails, setDayDetails] = useState({ duty: [], absences: [] });
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  // Fetch day details based on the selected date
  const fetchDayDetails = async () => {
    if (!date) {
      toast.warning("Please select a date.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
      const response = await axios.get(
        `${apiUrl}/api/admin/vacancies/day-details`,
        {
          params: { date },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDayDetails(response.data.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching day details:", err);
      setError("Failed to fetch day details.");
      setLoading(false);
    }
  };

  // Open modal with specific data (either duty or absence)
  const openModal = (data) => {
    setModalData(data);
  };

  // Close modal
  const closeModal = () => {
    setModalData(null);
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg mb-20">
      <h1 className="text-2xl font-bold text-center text-[#8AB53E] mb-8">
        {t("Team Duty")} & {t("Absence Tracker")}
      </h1>

      <div className="flex items-center justify-center mb-6">
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all w-full max-w-xs mr-4"
        />
        <button
          onClick={fetchDayDetails}
          className="bg-gradient-to-r from-[#8AB53E] to-[#a2ce56] text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg hover:from-indigo-500 hover:to-blue-500 transition-all duration-300"
        >
          {t("Track")}
        </button>
      </div>

      {loading && (
        <p className="text-center text-lg font-semibold">Loading...</p>
      )}
      {error && (
        <p className="text-center text-red-500 font-semibold">{error}</p>
      )}

      {!loading && !error && (
        <div className="mt-6">
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2 text-blue-600">
              {t("On Duty")}
            </h2>
            {dayDetails?.duty?.length === 0 ? (
              <p className="text-gray-600">{t("No data available")}.</p>
            ) : (
              <ul className="space-y-2">
                {dayDetails?.duty?.map((duty, index) => (
                  <li
                    key={index}
                    onClick={() => openModal(duty)}
                    className="p-4 bg-blue-100 rounded-lg shadow-md hover:shadow-lg cursor-pointer hover:bg-blue-200 transition-all"
                  >
                    <strong className="text-blue-600">
                      {duty.employeeName}
                    </strong>{" "}
                    (From: {new Date(duty.DT_INI).toLocaleTimeString()} - To:{" "}
                    {new Date(duty.DT_FIM).toLocaleTimeString()})
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-2 text-red-600">
              {t("Absences")}
            </h2>
            {dayDetails?.absences?.length === 0 ? (
              <p className="text-gray-600">{t("No data available")}.</p>
            ) : (
              <ul className="space-y-2">
                {dayDetails?.absences?.map((absence, index) => (
                  <li
                    key={index}
                    onClick={() => openModal(absence)}
                    className="p-4 bg-red-100 rounded-lg shadow-md hover:shadow-lg cursor-pointer hover:bg-red-200 transition-all"
                  >
                    <strong className="text-red-600">
                      {absence.employeeName}
                    </strong>{" "}
                    (From: {new Date(absence.DT_INI).toLocaleTimeString()} - To:{" "}
                    {new Date(absence.DT_FIM).toLocaleTimeString()})
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}

      {/* Modal for displaying detailed info */}
      {modalData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8 w-96 shadow-lg relative">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 transition-all"
            >
              &times;
            </button>
            <h2 className="text-2xl font-semibold mb-4 text-center text-purple-600">
              Details for {modalData.employeeName}
            </h2>
            <div className="space-y-2">
              <p className="text-lg">
                <strong>Company:</strong> {modalData.EMPRESA}
              </p>
              <p className="text-lg">
                <strong>From:</strong>{" "}
                {new Date(modalData.DT_INI).toLocaleString()}
              </p>
              <p className="text-lg">
                <strong>To:</strong>{" "}
                {new Date(modalData.DT_FIM).toLocaleString()}
              </p>
              {modalData.reason && (
                <p className="text-lg">
                  <strong>Reason:</strong> {modalData.reason}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DayDetails;
