import { useTranslation } from "react-i18next";
import FooterNavigation from "../FooterNavigation";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";


const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    current_password: '', // name
    new_password: '', // gender
    confirm_new_password: '',
  });
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleUpdateForm = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${apiUrl}/api/password/change`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      console.log(response);


      if (response.ok) {
        toast.success(t('Record updated successfully'));

        setTimeout(() => {
          navigate('/settings');
        }, 2000);

      } else {
        toast.error(t('Something went wrong'));
        console.log('no catch');
      }
    } catch (error) {
      toast.error(t('Something went wrong') + error);
      console.log(error);
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData({
      ...formData,
      [name]: value
    });
  }

  return (
    <>
      {/* <CommonHeaderWithMenu title={t('Change Passoword')} /> */}
      <ToastContainer />
      <div className="p-5 border-b flex items-center gap-x-5">
        <Link to="/settings" > <FaAngleLeft /> </Link>
        <span className="text-gray-500 font-semibold text-lg">{t('Change Password')}</span>
      </div>
      <div className="px-5 pt-4">
        <form onSubmit={handleUpdateForm}>
          <div className="flex flex-col justify-center p-2 gap-y-4">
            <div className="flex flex-col gap-y-1">
              <label className="text-gray-700 font-medium">{t('Current Password')}</label>
              <input type="password" className="w-full p-2 rounded border border-gray-400" name="current_password" value={formData.current_password} onChange={handleChange} required />
            </div>

            <div className="flex flex-col gap-y-1">
              <label className="text-gray-700 font-medium">{t('New Password')}</label>
              <input type="password" className="w-full p-2 rounded border border-gray-400" name="new_password" value={formData.new_password} onChange={handleChange} required />
            </div>

            <div className="flex flex-col gap-y-1">
              <label className="text-gray-700 font-medium">{t('Retype New Password')}</label>
              <input type="password" className="w-full p-2 rounded border border-gray-400" name="confirm_new_password" value={formData.confirm_new_password} onChange={handleChange} required />
            </div>

            <div className="block mt-5">
              <button type="submit" className="w-full py-3 bg-emerald-500 text-white rounded font-semibold" >{t('Save Changes')}</button>
            </div>
          </div>
        </form>
      </div>
      <FooterNavigation />
    </>
  );
}

export default Profile;