import React, { useEffect, useState } from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// Modal Component
const Modal = ({ isVisible, onClose, onSubmit }) => {
  const [rejectionReason, setRejectionReason] = useState("");
  const { t } = useTranslation();

  if (!isVisible) return null;

  const handleSubmit = () => {
    if (rejectionReason) {
      onSubmit(rejectionReason);
      setRejectionReason(""); // Clear input after submission
      onClose();
    } else {
      toast.success("Please enter a rejection reason");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-11/12">
        <h2 className="text-lg font-bold mb-4">
          {t("Enter Rejection Reason")}
        </h2>
        <textarea
          className="w-full p-2 border border-gray-300 rounded mb-4"
          rows="4"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
          placeholder={t("Enter reason for rejection")}
        />
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-400 text-white rounded mr-2 hover:bg-gray-500"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            {t("Reject")}
          </button>
        </div>
      </div>
    </div>
  );
};

const Approval = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();

  // Assume the token is stored in localStorage after login
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      fetchAllRecords();
    } else {
      alert("No token found. Please log in.");
    }
  }, [token]);

  // Fetch all records from the backend
  const fetchAllRecords = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/admin/workflow/records`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch records");
      }

      const data = await response.json();
      setRecords(data.allRecords);
    } catch (error) {
      console.error("Error fetching records:", error);
      alert("Failed to fetch records. Please try again.");
    }
    setLoading(false);
  };

  // Approve a record
  const approveRecord = async (record) => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/workflow/approve`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token in headers
        },
        body: JSON.stringify({
          empresa: record.EMPRESA,
          recordRHID: record.RHID,
          dtIniDet: record.DT_INI_DET,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to approve record");
      }

      const data = await response.json();
      toast.success(data.message);
      fetchAllRecords(); // Refresh records after approval
    } catch (error) {
      console.error("Error approving record:", error);
      toast.error("Failed to approve record. Please try again.");
    }
  };

  // Open modal for rejection
  const handleRejectClick = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  // Reject a record
  // Reject a record
  // Reject a record
  const rejectRecord = async (rejectionReason) => {
    if (!currentRecord) return;

    try {
      // Ensure the dtIniDet is formatted as expected by the backend
      const formattedDtIniDet = new Date(currentRecord.DT_INI_DET)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const response = await fetch(`${apiUrl}/api/admin/workflow/reject`, {
        method: "PUT", // Use PUT method to align with update logic in the backend
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token in headers
        },
        body: JSON.stringify({
          empresa: currentRecord.EMPRESA,
          recordRHID: currentRecord.RHID,
          dtIniDet: formattedDtIniDet, // Use formatted date
          MOT_REJ: rejectionReason, // Use MOT_REJ as expected by the backend
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to reject record");
      }

      const data = await response.json();
      toast.success(data.message);

      // Update only the rejected record's status in the local state
      const updatedRecords = records.map((record) => {
        if (
          record.EMPRESA === currentRecord.EMPRESA &&
          record.RHID === currentRecord.RHID &&
          record.DT_INI_DET === currentRecord.DT_INI_DET
        ) {
          return { ...record, MOT_REJ: rejectionReason };
        }
        return record;
      });
      console.log(updatedRecords);
      // Move rejected record to the top
      const rejectedRecord = updatedRecords.find(
        (record) =>
          record.EMPRESA === currentRecord.EMPRESA &&
          record.RHID === currentRecord.RHID &&
          record.DT_INI_DET === currentRecord.DT_INI_DET
      );
      const remainingRecords = updatedRecords.filter(
        (record) =>
          !(
            record.EMPRESA === currentRecord.EMPRESA &&
            record.RHID === currentRecord.RHID &&
            record.DT_INI_DET === currentRecord.DT_INI_DET
          )
      );

      setRecords([rejectedRecord, ...remainingRecords]);
      setCurrentRecord(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error rejecting record:", error);
      toast.error("Failed to reject record. Please try again.");
    }
  };

  return (
    <>
      <CommonHeaderWithMenu title={t("Approval")} />
      <div className="p-6 bg-gray-100 min-h-screen mb-20">
        {/* <h1 className="text-2xl font-bold mb-4">Approval System</h1> */}
        {/* <p className="text-red-600 font-semibold mb-4">
          Possibility to approve/reject workflow records for your team members
        </p>
        <p className="text-gray-500 mb-6">
          (Each record is displayed in a box, with buttons to approve or reject)
        </p> */}

        {/* Modal for rejection reason */}
        <Modal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSubmit={rejectRecord}
        />

        {/* Record List */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {loading ? (
            <div className="text-center col-span-full">
              {t("Loading records")}...
            </div>
          ) : (
            records.map((record, index) => (
              <div
                key={index}
                className="bg-white p-4 shadow-lg rounded-md flex flex-col justify-between border border-gray-200"
              >
                <div className="mb-4">
                  <p className="font-semibold">
                    <span className="text-gray-700">EMPRESA:</span>{" "}
                    {record.EMPRESA}
                  </p>
                  <p className="font-semibold">
                    <span className="text-gray-700">RHID:</span> {record.RHID}
                  </p>
                  <p className="font-semibold">
                    <span className="text-gray-700">DT_INI_DET:</span>
                    {new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false, // Display time in 24-hour format
                      timeZone: "UTC", // Adjust to the desired time zone
                    }).format(new Date(record.DT_INI_DET))}
                  </p>

                  <p className="font-semibold">
                    <span className="text-gray-700">MOT_REJ:</span>{" "}
                    <span
                      className={`${
                        record.MOT_REJ === "Approved"
                          ? "text-green-600"
                          : record.MOT_REJ
                          ? "text-red-600"
                          : "text-gray-500"
                      }`}
                    >
                      {record.MOT_REJ || "None"}
                    </span>
                  </p>
                </div>

                <div className="flex justify-between">
                  <button
                    onClick={() => approveRecord(record)}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    {t("Approve")}
                  </button>
                  <button
                    onClick={() => handleRejectClick(record)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    {t("Reject")}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default Approval;
