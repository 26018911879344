import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import appionmentImage from "../../assets/images/GroupAppionment.png";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

const AppointmentSection = ({ empresa }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [status, setStatus] = useState("Entrance"); // Initial status can be "Entrance" or "Exit"
  const [lastEntry, setLastEntry] = useState(null);
  const [lastExit, setLastExit] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  // Decode the token to extract RHID and DT_ADMISSAO
  let rhid, dtAdmissao;
  if (token) {
    const decodedToken = jwtDecode(token);
    rhid = decodedToken.RHID;
    dtAdmissao = decodedToken.DT_ADMISSAO;
  }

  // Set default values if not provided
  rhid = rhid || "defaultRhid"; // Replace with an appropriate default value
  dtAdmissao = dtAdmissao || "2020-01-01"; // Replace with an appropriate default value

  // Function to update time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Fetch the last entry and exit records
    const fetchLastEntryAndExit = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/attendance/last?EMPRESA=${empresa}&RHID=${rhid}&DT_ADMISSAO=${dtAdmissao}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`, // Include token if needed
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setLastEntry(result.lastEntry);
          setLastExit(result.lastExit);
        } else {
          console.error(
            "Failed to fetch last entry and exit:"
            // await response.text()
          );
        }
      } catch (error) {
        console.error("Error fetching last entry and exit:", error);
      }
    };

    fetchLastEntryAndExit();
  }, [empresa, rhid, dtAdmissao, apiUrl, token]);

  const handleEntrance = async () => {
    const data = {
      EMPRESA: empresa || "demo",
      RHID: rhid,
      DT_ADMISSAO: dtAdmissao,
      TP_OCORRENCIA: "01",
    };

    try {
      const response = await fetch(`${apiUrl}/api/attendance/entry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if needed
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error("Failed to record entrance:", await response.text());
        return;
      }

      const result = await response.json();
      console.log("Entrance recorded:", result);
      setStatus("Exit"); // Update status to "Exit" after entrance is recorded
      setLastEntry(currentTime); // Update last entry time
    } catch (error) {
      console.error("Error recording entrance:", error);
    }
  };

  const handleExit = async () => {
    const data = {
      EMPRESA: empresa || "demo",
      RHID: rhid,
      DT_ADMISSAO: dtAdmissao,
      TP_OCORRENCIA: "01",
    };

    try {
      const response = await fetch(`${apiUrl}/api/attendance/exit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if needed
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error("Failed to record exit:", await response.text());
        return;
      }

      const result = await response.json();
      console.log("Exit recorded:", result);
      setStatus("Entrance"); // Update status to "Entrance" after exit is recorded
      setLastExit(currentTime); // Update last exit time
    } catch (error) {
      console.error("Error recording exit:", error);
    }
  };

  // Determine pie chart data based on current status
  const dataForChart = [
    {
      id: status === "Entrance" ? "Current Time" : "Time Remaining",
      value: status === "Entrance" ? 75 : 25,
      color: "#FFB900", // Muted orange segment
    },
    {
      id: "Other",
      value: status === "Entrance" ? 25 : 75,
      color: "#5E93FA", // Muted blue segment
    },
  ];

  return (
    <>
      {/* <div className="mb-4">
        <h2 className="text-gray-700 text-xl font-semibold">Appointment</h2>
        <p className="text-gray-600 text-sm">
          Your last appointment was
          {lastExit
            ? ` EXIT on ${new Date(
                lastExit
              ).toLocaleDateString()} at ${new Date(
                lastExit
              ).toLocaleTimeString()} hours`
            : " no recent exits."}{" "}
          <br />
          Your last entry was
          {lastEntry
            ? ` ENTRY on ${new Date(
                lastEntry
              ).toLocaleDateString()} at ${new Date(
                lastEntry
              ).toLocaleTimeString()} hours`
            : " no recent entries."}
          .
        </p>
      </div> */}
      <div
        style={{
          // backgroundImage: `url(${appionmentImage})`,
          backgroundPosition: "center",
          width: "100%",
        }}
        className="rounded-xl shadow-lg flex items-center bg-[#8AB53E] justify-between relative mt-6"
      >
        {/* Text Section */}
        <div className="flex-1 px-4 py-4">
          <h2 className="text-white text-[21px] font-semibold">
            {t("Appointment")}
          </h2>
          <p className="text-white text-[13px]">
            {/* Current Time {currentTime.toLocaleTimeString()} */}
            <br />
            {t("lastExit")}
            {lastExit
              ? ` ${t("exitOn")} ${new Date(
                  lastExit
                ).toLocaleDateString()} at ${new Date(
                  lastExit
                ).toLocaleTimeString()} hours`
              : "-"}{" "}
            <br />
            {t("lastEntry")}
            {lastEntry
              ? ` ${t("entryOn")} ${new Date(
                  lastEntry
                ).toLocaleDateString()} at ${new Date(
                  lastEntry
                ).toLocaleTimeString()} hours`
              : "-"}
          </p>
          <div className="flex">
            <button
              onClick={handleEntrance}
              className={`${
                status === "Entrance"
                  ? "bg-white text-green-500"
                  : "bg-gray-300"
              } font-semibold px-4 py-2 rounded-xl mr-2`}
              disabled={status !== "Entrance"}
            >
              {t("enter")}
            </button>
            <button
              onClick={handleExit}
              className={`${
                status === "Exit" ? "bg-red-500 text-white" : "bg-gray-300"
              } font-semibold px-4 py-2 rounded-xl`}
              disabled={status !== "Exit"}
            >
              {t("exit")}
            </button>
          </div>
        </div>

        {/* Pie Chart Section */}
        <div className="relative w-32 h-32 mr-4">
          <ResponsivePie
            data={dataForChart}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.7}
            enableRadialLabels={false}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            enableSliceLabels={false}
            colors={{ datum: "data.color" }}
            borderWidth={0}
            isInteractive={false}
            animate={true}
            motionConfig="stiff"
          />
          <div
            className="absolute inset-0 flex flex-col items-center justify-center
         text-white text-center"
          >
            <div className="text-xs">{t("currentTime")}</div>
            <div className="text-sm font-bold">
              {currentTime.toLocaleTimeString()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentSection;
