import React, { useEffect, useState } from "react";

const RetributiveData = () => {
  const [retributiveData, setRetributiveData] = useState(null);
//   console.log(retributiveData);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/cadastre/retributive`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setRetributiveData(data.retributiveData[0]);
      } catch (error) {
        console.error("Error fetching retributive data:", error);
      }
    };

    fetchData();
  }, []);

  if (!retributiveData) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md mt-6">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Retributive Data
        </h1>
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <table className="min-w-full bg-white">
            <tbody className="text-gray-700">
              <tr>
                <th className="py-3 px-6 text-left bg-gray-50 font-semibold text-gray-600 uppercase tracking-wider">
                  Company
                </th>
                <td className="py-3 px-6">{retributiveData.EMPRESA}</td>
              </tr>
              <tr className="border-t">
                <th className="py-3 px-6 text-left bg-gray-50 font-semibold text-gray-600 uppercase tracking-wider">
                  Admission Date
                </th>
                <td className="py-3 px-6">{retributiveData.DT_ADMISSAO}</td>
              </tr>
              <tr className="border-t">
                <th className="py-3 px-6 text-left bg-gray-50 font-semibold text-gray-600 uppercase tracking-wider">
                  IRS Type
                </th>
                <td className="py-3 px-6">{retributiveData.TP_IRS}</td>
              </tr>
              <tr className="border-t">
                <th className="py-3 px-6 text-left bg-gray-50 font-semibold text-gray-600 uppercase tracking-wider">
                  IRS Table
                </th>
                <td className="py-3 px-6">{retributiveData.TABELA_IRS}</td>
              </tr>
              <tr className="border-t">
                <th className="py-3 px-6 text-left bg-gray-50 font-semibold text-gray-600 uppercase tracking-wider">
                  Marital Status
                </th>
                <td className="py-3 px-6">{retributiveData.EST_CIVIL_IRS}</td>
              </tr>
              <tr className="border-t">
                <th className="py-3 px-6 text-left bg-gray-50 font-semibold text-gray-600 uppercase tracking-wider">
                  IBAN
                </th>
                <td className="py-3 px-6">{retributiveData.IBAN1}</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RetributiveData;
